import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Video from './VideoComponent';
import Network from './NetworkComponent';

const App = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Video} />
      <Route exact path="/network" component={Network} />
    </Switch>
  </Router>
);

export default App;
