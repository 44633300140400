import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// import Loader from '../../../../assets/images/loader.svg';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    top: '0%',
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  text: {
    textAlign: 'center',
    width: '100%',
  },
  image: {
    width: '100%',
    height: 'auto',
    maxWidth: '80px',
    maxWheight: '80px',
  },
}));

function Reconnect(props) {
  const { title, text, open } = props;
  const classes = useStyles();

  return (
    <div>
      <Backdrop className={classes.backdrop} open={open}>
        <div className={classes.text}>
          {/* <img className={classes.image} src={Loader} alt="Reconnecting" /> */}
          <Typography variant="h5" display="block">
            {title}
          </Typography>
          <Typography variant="subtitle1" display="block">
            {text}
          </Typography>
        </div>
      </Backdrop>
    </div>
  );
}

Reconnect.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
};

export default Reconnect;
