import React from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

function checkTURNServer(turnConfig, timeout) {
  return new Promise((resolve) => {
    let promiseResolved = false;
    setTimeout(() => {
      if (promiseResolved) return;
      resolve(false);
      promiseResolved = true;
    }, timeout || 5000);

    const MyPeerConnection =
      window.RTCPeerConnection ||
      window.mozRTCPeerConnection ||
      window.webkitRTCPeerConnection; // compatibility for firefox and chrome
    const pc = new MyPeerConnection({ iceServers: [turnConfig] });
    const noop = () => {};
    pc.createDataChannel(''); // create a bogus data channel
    pc.createOffer((sdp) => {
      if (sdp.sdp.indexOf('typ relay') > -1) {
        // sometimes sdp contains the ice candidates...
        promiseResolved = true;
        resolve(true);
      }
      pc.setLocalDescription(sdp, noop, noop);
    }, noop); // create offer and set local description
    pc.onicecandidate = (ice) => {
      // listen for candidate events
      if (
        promiseResolved ||
        !ice ||
        !ice.candidate ||
        !ice.candidate.candidate ||
        !(ice.candidate.candidate.indexOf('typ relay') > -1)
      )
        return;
      promiseResolved = true;
      resolve(true);
    };
  });
}

class TurnCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      loading: false,
    };
  }

  start() {
    this.setState({ results: [], loading: true }, () => {
      const servers = window._env_.REACT_APP_TURN_SERVERS.split(',');
      const transports = ['udp', 'tcp'];
      const username = window._env_.REACT_APP_TURN_USERNAME;
      const password = window._env_.REACT_APP_TURN_PASSWORD;
      const domain = window._env_.REACT_APP_TURN_DOMAIN;

      let pendingResponses = servers.length * transports.length;
      servers.forEach((server) => {
        transports.forEach((transport) => {
          checkTURNServer({
            urls: `turn:${server}.${domain}:3478?transport=${transport}`,
            username,
            credential: `${password}-${server}`,
          })
            .then((bool) => {
              pendingResponses -= 1;
              this.setState(({ results }) => {
                const newResults = [
                  ...results,
                  {
                    server,
                    transport,
                    success: bool,
                  },
                ];
                newResults.sort((result1, result2) => {
                  if (result1.server !== result2.server) {
                    return result1.server - result2.server;
                  }
                  if (result1.transport !== result2.transport) {
                    return result1.transport === 'udp';
                  }
                  return 0;
                });
                return {
                  results: newResults,
                  loading: pendingResponses > 0,
                };
              });
            })
            .catch((err) => {
              pendingResponses -= 1;
              if (pendingResponses === 0) {
                this.setState({ loading: false });
              }
              console.error(err);
              alert(err);
            });
        });
      });
    });
  }

  render() {
    const { results, loading } = this.state;
    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={() => this.start()}
        >
          Start
        </Button>
        {results.map(({ server, transport, success }) => (
          <Typography
            variant="subtitle1"
            gutterBottom
            key={`${server}:${transport}`}
          >
            {`${server} ${transport} ${success}`}
          </Typography>
        ))}
      </div>
    );
  }
}

export default TurnCheck;
