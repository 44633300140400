import React from 'react';

import TurnCheck from './networkChecks/TurnCheck';
// import IPResolutionCheck from './networkChecks/IPResolutionCheck';

const NetworkComponent = () => (
  <div>
    {/* <APICheck /> */}
    {/* <IPResolutionCheck /> */}
    <TurnCheck />
    {/* <MSTestLink /> */}
  </div>
);

export default NetworkComponent;
